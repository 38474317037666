import React, { Fragment } from 'react';
import { Image, Menu, Responsive } from 'semantic-ui-react';
import logoBig from './logos/aulogo_dk_var2_hvid.png';
import logoSmall from './logos/aulogo_hvid.png';
import styled from 'styled-components';

const Print = styled.div`
  @media print {
    display: none;
  }
`;

export interface HeaderProps {}

const Header: React.SFC<HeaderProps> = () => {
  return (
    <Print>
      <Menu size="small" inverted style={{ backgroundColor: '#4daaff' }} attached>
        <Responsive minWidth={500} as={Fragment}>
          <Menu.Item>
            <Image style={{ height: '30px', margin: 0 }} src={logoBig} />
          </Menu.Item>
          <Menu.Item>
            <span style={{ fontSize: '18px' }}>Faciliteret Multi-Source Feedback</span>
          </Menu.Item>
        </Responsive>
        <Responsive maxWidth={499} as={Fragment}>
          <Menu.Item>
            <Image style={{ height: '30px' }} src={logoSmall} />
          </Menu.Item>
          <Menu.Item>Faciliteret Multi-Source Feedback</Menu.Item>
        </Responsive>
      </Menu>
    </Print>
  );
};

export default Header;
