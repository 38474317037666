import Evaluation from 'classes/Evaluation';
import { insertOrReplace } from 'redux/misc/utilityFunctions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [] as Evaluation[]
};

const evaluationReducer = createSlice({
  name: 'evaluations',
  initialState,
  reducers: {
    setEvaluations: (state, action: PayloadAction<Evaluation[]>) => {
      const evaluations = action.payload;
      for (let evaluation of evaluations) {
        insertOrReplace(state.items, evaluation);
      }
    },
    addEvaluation: (state, action: PayloadAction<Evaluation>) => {
      const evaluation = action.payload;
      insertOrReplace(state.items, evaluation);
    },
    removeEvaluation: (state, action: PayloadAction<number>) => {
      const index = state.items.findIndex((evaluation) => evaluation.id === action.payload);
      state.items.splice(index, 1);
    }
  }
});

export default evaluationReducer;
