import { createSlice } from '@reduxjs/toolkit';
import Question from 'classes/Question';
import Category from 'classes/Category';

interface Answer {
  value: number;
  dunno: boolean;
  comment?: string;
}

const initialState = {
  questions: [] as Question[],
  answers: {} as { [key: string]: Answer },
  feedback: {},
  categories: [] as Category[]
};

const questionReducer = createSlice({
  name: 'questions',
  initialState: initialState,
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    answer: (state: any, action) => {
      let answer: Answer;

      if (action.payload.comment) {
        answer = {
          value: action.payload.value,
          dunno: action.payload.dunno,
          comment: action.payload.comment
        };
      } else {
        answer = {
          value: action.payload.value,
          dunno: action.payload.dunno
        };
      }

      state.answers[action.payload.id] = answer;
    },
    feedback: (state: any, action) => {
      state.feedback[action.payload.id] = action.payload.value;
    }
  }
});

export default questionReducer;
