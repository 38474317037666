import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import LoadingPage from 'components/misc/LoadingPage';
import './App.scss';
const Frontpage = lazy(() => import('./components/Frontpage'));
const Logout = lazy(() => import('./components/auth/Logout'));
const AdminApp = lazy(() => import('components/admin/AdminApp'));
const AddAnswering = lazy(() => import('components/reciever/AddAnswering'));
const Evaluation = lazy(() => import('components/evaluation/Evaluation'));

export interface AppProps {}

// eslint-disable-next-line no-extend-native
String.prototype.toTitleCase = function() {
  return this[0].toUpperCase() + this.substr(1);
};
// eslint-disable-next-line no-extend-native
Number.prototype.round = function() {
  return Math.round((this + Number.EPSILON) * 10) / 10;
};

const App: React.FC<AppProps> = () => {
  return (
    <div>
      <Layout>
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            <Route path="/admin" component={AdminApp} />
            <Route path="/addanswering" component={AddAnswering} />
            <Route path="/evaluation" component={Evaluation} />
            <Route path="/logout" component={Logout} />
            <Route exact path="/" component={Frontpage} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </Layout>
    </div>
  );
};

export default App;
