import React from 'react';
import { Icon, Menu, Responsive } from 'semantic-ui-react';

export interface FooterProps {}

const Footer: React.SFC<FooterProps> = () => {
  return (
    <>
      <Responsive minWidth={500}>
        <Menu color="blue" inverted size="small" attached>
          <Menu.Item>
            <Icon name="copyright outline" /> 2020
          </Menu.Item>
          <Menu.Item onClick={() => window.open('https://cesu.au.dk/')}>CESU</Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>Af Iris Maria Pedersen, Mette Krogh Christensen og Thomas Jensen</Menu.Item>
          </Menu.Menu>
        </Menu>
      </Responsive>
      <Responsive maxWidth={500}>
        <Menu
          color="blue"
          inverted
          size="small"
          attached
          vertical
          fluid
          style={{ textAlign: 'center' }}
        >
          <Menu.Item>
            <div>
              <Icon name="copyright outline" /> 2020
            </div>
          </Menu.Item>
          <Menu.Item>Af Iris Maria Pedersen, Mette Krogh Christensen og Thomas Jensen</Menu.Item>
        </Menu>
      </Responsive>
    </>
  );
};

export default Footer;
