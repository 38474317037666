import React from 'react';
import BlueHeader from './Header';
import Footer from './Footer';

export interface LayoutProps {
  children: object;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <BlueHeader />
      {children}
      <div style={{ flexGrow: 1 }} />
      <Footer />
    </div>
  );
};

export default Layout;
