import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import User from 'classes/User';
import Role from 'classes/Role';
import TokenUser from 'classes/TokenUser';

const initialState = {
  user: null as User,
  roles: [] as Role[],
  tokenUser: null as TokenUser
};

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      state.user = action.payload; // Vi modtager en token, som skal decodes til et user object
    },
    logout: (state) => {
      state.user = null;
    },
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
    },
    setTokenUser: (state, action: PayloadAction<TokenUser>) => {
      state.tokenUser = action.payload;
    }
  }
});

export default authReducer;
