import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import TokenUser from 'classes/TokenUser';

const initialState = {
  items: [] as TokenUser[]
};

const tokenUserReducer = createSlice({
  name: 'givers',
  initialState,
  reducers: {
    setTokenUsers: (state, action: PayloadAction<TokenUser[]>) => {
      state.items = action.payload;
    }
  }
});

export default tokenUserReducer;
