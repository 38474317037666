import { combineReducers } from 'redux';
import authReducer from './auth';
import questionReducer from './questions';
import evaluationReducer from './evaluations';
import tokenUserReducer from './tokenUsers';

const rootReducer = combineReducers({
  questions: questionReducer.reducer,
  auth: authReducer.reducer,
  evaluations: evaluationReducer.reducer,
  tokenUsers: tokenUserReducer.reducer
});

export type ReduxState = ReturnType<typeof rootReducer>;

export default rootReducer;
